import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
/* import ClassStudentsList from "./ClassStudentsList"; */
import { PermissionContext } from 'app/contexts/PermissionContext';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import InfosAboutCreateAndUpdate from 'app/components/InfosAboutCreateAndUpdate/InfosAboutCreateAndUpdate';
import { updateInputValue } from 'app/utils/appHelpers';
import { Box } from '@mui/material';
import BookItem from 'app/pages/app/book/BookItem';

const VolunteerAlocationFormResumeEdit = ({
  volunteerId,
  areaId,
  activitySelected,
  getVolunteerAreaHistory,
  handleCloseActivityEdit,
  isButtonDisabled,
  setIsButtonDisabled,
}) => {
  const { theme } = useJumboTheme();
  const animatedComponents = makeAnimated();
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    pessoa_id: '',
    voluntario_id: '',
    codigo_antigo_voluntariado: '',
    status: '',
    data_inicio: '',
    data_fim: '',
    departamento_id: '',
    area_id: '',
    livro_id: '',
    local: '',
    ficha: '',
    atividade: '',
    observacao: '',
    voluntario_desde: '',
    motivo_encerramento: null,
    obs_encerramento: null,
    teve_vivencia_pratica: false,
    quais_trabalhos: false,
    confirmar_voluntariado: false,
    encerrar_voluntariado: false,
  };

  const { id } = useParams();

  const [users, setUsers] = useState(initialValues);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [status, setStatus] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [activities, setActivities] = useState([]);
  const [books, setBooks] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [editInput, setEditInput] = useState(true);

  const [userPreferences, setUserPreferences] = useState([
    { dia_da_semana: '', horario_inicio: '', horario_fim: '' },
  ]);

  const [userVolunteerOut, setUserVolunteerOut] = useState([
    {
      nome_trabalho_voluntario_fora: '',
      local_fora: '',
      data_inicio_voluntario_fora: '',
      obs_fora: '',
    },
  ]);

  const { hasPermission } = useContext(PermissionContext);

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const isInputEditable = editInput === true;
  const isStatusDisabled = status === false;

  const storedUserId = localStorage.getItem('storedUserId');

  const getDepartments = useCallback(async () => {
    try {
      ApiService.get(`/departamentos`)
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAtividades = useCallback(async () => {
    try {
      ApiService.get(`/atividades-voluntarios`)
        .then((response) => {
          console.log(response.data);
          setActivities(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAreaActivity = useCallback(
    async (id) => {
      try {
        ApiService.get(`/atividades-do-voluntario/${id}`)
          .then((response) => {
            console.log(response.data);
            setUsers({
              ...users,
              area_id: response.data.area_id,
              departamento_id: response.data.departamento_id,
              local: response.data.local,
              data_inicio: response.data.data_inicio,
              data_fim: response.data.data_fim,
              motivo_encerramento: response.data.motivo_encerramento,
              status: response.data.status,
              Voluntario: response.data.Voluntario,
              livro_id: response.data.Equipe.length > 0 ? response?.data?.Equipe[0]?.livro_id : undefined,
              quem_aceitou_atividade_do_voluntario: response.data.quem_aceitou_atividade_do_voluntario,
              quem_atualizou_atividade_do_voluntario: response.data.quem_atualizou_atividade_do_voluntario,
              createdAt: response.data.createdAt,
              updatedAt: response.data.updatedAt,
            });
            setActivitiesSelected(response.data.atividade_voluntario_id);
            if (response.data.status === 'ativo') {
              setStatus(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [users]
  );

  const getLivros = useCallback(async () => {
    try {
      ApiService.get(`/livros`)
        .then((response) => {
          console.log(response.data);
          setBooks(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getDepartments();
    getAreas();
    getAtividades();
    getLivros();
    getAreaActivity(activitySelected);
  }, []);


  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log(values);

    if (volunteerId) {
      if (values.encerrar_voluntariado == true) {
        //encerrar voluntariado
        const result = await Swal.fire({
          title: 'Tem certeza que deseja encerrar?',
          text: 'Não será possível reverter a ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          reverseButtons: true,
        });

        /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */

        if (result.value) {
          const allValues = {
            ...values,
            id_quem_atualizou: storedUserId,
          }
          await ApiService.put(
            `/atividades-do-voluntario/encerrar/${activitySelected}`,
            { values: allValues }
          )
            .then((response) => {
              getVolunteerAreaHistory(users.area_id);
              toast('success', 'Atuação na atividade encerrada');
              setIsButtonDisabled(true);
              getAreaActivity(response.data?.atividade_do_voluntario_id)
            })
            .catch((error) => {
              toast('error', 'Ocorreu um erro');
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
            });
        }
      } else {
        //atualiza form sem encerrar o voluntariado
        if (activitySelected) {
          const allValuesEdit = {
            ...values,
            data_fim: values.encerrar_voluntariado == false ? undefined : values.data_fim,
            motivo_encerramento: values.encerrar_voluntariado == false ? undefined : values.motivo_encerramento,
            horario_voluntariado: userPreferences,
            trabalho_voluntario_fora_feesp: userVolunteerOut,
            atividade_selecionada_id: activitiesSelected,
            id_quem_atualizou: storedUserId
          };

          await ApiService.put(
            `/atividades-do-voluntario/${activitySelected}`,
            { values: allValuesEdit }
          )
            .then((response) => {
              toast('success', 'Atividade atualizada com sucesso');

              getVolunteerAreaHistory(users.area_id);
              setEditInput(true);
              getAreaActivity(response.data?.updatedAtividade.atividade_do_voluntario_id)
            })
            .catch((error) => {
              toast('error', error.response.data.error);
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
            });
        }
      }
    }

    setSubmitting(false);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : 'white',
      border: state.isFocused
        ? '1px solid #7352C7'
        : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),
  };

  const optionsVolunteerActivities = activities?.map((result) => {
    return {
      value: result.atividade_voluntario_id,
      label: result.nome,
    };
  });

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Paper sx={{p: '20px', height: '500px'}}>
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
          <Box
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            mb={2}
          >
            <Button
              size='small'
              variant='contained'
              onClick={handleCloseActivityEdit}
              color='success'
            >
              Voltar
            </Button>
          </Box>
          <Typography variant={'h2'} textAlign={'center'} mb={2}>
            Editar atividade
          </Typography>
          <Formik
            initialValues={users}
            //validationSchema={validationSchema}
            enableReinitialize
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, handleChange }) => {
              const selectedArea = areas.find(
                (area) => area.area_id === values.area_id
              );

              const selectedBook = books?.books?.find(
                (book) => book.livro_id === values.livro_id
              );

              if (selectedBook) {
                values.departamento_id =
                  selectedBook?.Departamento?.departamento_id;
                values.local = selectedBook?.Sala?.local;
              }

              return (
                <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                  <Grid container spacing={3} alignContent={'center'}>
                    <>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <InputLabel shrink id='area_id'>
                            Área
                          </InputLabel>
                          <JumboSelectField
                            labelId='area_id'
                            id='area_id'
                            name='area_id'
                            value={updateInputValue(users, values, 'area_id')}
                            label='Área'
                            disabled={isDisabled || areaId} //fdg
                            notched
                            onChange={(e) => {
                              setUsers({
                                ...users,
                                area_id: e.target.value,
                              });
                              getVolunteerAreaHistory(e.target.value);
                            }}
                          >
                            <MenuItem value={''}>Sem seleção</MenuItem>
                            {areas.length > 0 &&
                              areas.map((x) => (
                                <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                              ))}
                          </JumboSelectField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <InputLabel shrink id='departamento_id'>
                            Departamento
                          </InputLabel>
                          <JumboSelectField
                            labelId='departamento_id'
                            id='departamento_id'
                            name='departamento_id'
                            value={updateInputValue(
                              users,
                              values,
                              'departamento_id'
                            )}
                            label='Departamento'
                            disabled={isDisabled}
                            sx={{ background: '#ffffff' }}
                            notched
                          >
                            <MenuItem value={''}>Sem seleção</MenuItem>
                            {(selectedArea?.departamento || []).map((x) => (
                              <MenuItem value={x.departamento_id}>
                                {x.nome}
                              </MenuItem>
                            ))}
                          </JumboSelectField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <InputLabel shrink id='local'>
                            Local
                          </InputLabel>
                          <JumboSelectField
                            labelId='local'
                            id='local'
                            name='local'
                            value={updateInputValue(users, values, 'local')}
                            label='Local'
                            disabled={isDisabled}
                            notched
                          >
                            <MenuItem value={'Casa Transitória'}>
                              Casa Transitória
                            </MenuItem>
                            <MenuItem value={'Sede'}>Sede</MenuItem>
                            <MenuItem value={'Outro'}>Outro</MenuItem>
                          </JumboSelectField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <JumboTextField
                          fullWidth
                          name='data_inicio'
                          type='date'
                          label='Data de início'
                          sx={{ background: '#ffffff' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={isDisabled}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <div style={{ position: 'relative' }}>
                          <Select
                            id='select-two'
                            styles={customStyles}
                            options={optionsVolunteerActivities}
                            components={animatedComponents}
                            onChange={(newValue) =>
                              setActivitiesSelected(newValue.value)
                            }
                            value={optionsVolunteerActivities.filter(
                              (option) => option.value === activitiesSelected
                            )}
                            placeholder='Selecione Atividade'
                            isDisabled={isDisabled}
                          />

                          <Typography
                            style={{
                              position: 'absolute',
                              top: '-9px',
                              left: '10px',
                              background: '#ffffff',
                              padding: '0 5px',
                              color: '#8595A6',
                              fontSize: '0.7rem',
                              fontFamily: 'NoirPro,Arial',
                            }}
                          >
                            Selecione atividade
                          </Typography>
                        </div>
                      </Grid>

                      {selectedArea?.nome.toLowerCase() ===
                        'assistência espiritual' && (
                        <>
                          <Grid item xs={3}>
                            <FormControl fullWidth>
                              <InputLabel shrink id='livro_id'>
                                Livro (Equipe)
                              </InputLabel>
                              <JumboSelectField
                                labelId='livro_id'
                                id='livro_id'
                                name='livro_id'
                                value={updateInputValue(
                                  users,
                                  values,
                                  'livro_id'
                                )}
                                label='Livro (Equipe)'
                                disabled={isDisabled/*  || status == false */}
                                sx={{ background: '#ffffff' }}
                                notched
                              >
                                <MenuItem value={''}>Sem seleção</MenuItem>
                                {books.books?.map((x) => (
                                  <MenuItem value={x.livro_id}>
                                    {x?.codigo_do_livro}
                                  </MenuItem>
                                ))}
                              </JumboSelectField>
                            </FormControl>
                          </Grid>

                          {selectedBook && (
                            <Grid item xs={12}>
                              <BookItem
                                item={selectedBook}
                                setBooks={(data) => setBooks([...data])}
                                books={books}
                                showButtons={false}
                              />
                            </Grid>
                          )}
                        </>
                      )}

                      <Grid item xs={12}>
                        {volunteerId && users?.data_inicio ? (
                          <>
                            <Grid item xs={12}>
                              <Accordion
                                expanded={
                                  users.status === 'ativo'
                                    ? updateInputValue(
                                        users,
                                        values,
                                        'encerrar_voluntariado'
                                      ) == true
                                      ? true
                                      : false
                                    : true
                                }
                                elevation={0}
                              >
                                <AccordionSummary
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Stack
                                    sx={{
                                      width: '100%',
                                      textAlign: { xs: 'center', lg: 'left' },
                                    }}
                                    direction={{ xs: 'column', lg: 'row' }}
                                    justifyContent='space-between'
                                    alignItems='center'
                                    spacing={2}
                                  >
                                    <Typography sx={{ fontSize: '1.05rem' }}>
                                      {users.status === 'ativo'
                                        ? 'Encerrar atuação na atividade?'
                                        : 'Atividade Encerrada'}
                                    </Typography>
                                    {/* {users.status === 'inativo' ? (
                                      <Tooltip
                                        title={'Editar data final e/ou motivo'}
                                        arrow
                                        TransitionComponent={Zoom}
                                      >
                                        <EditIcon
                                          onClick={() => setEditInput(!editInput)}
                                        />
                                      </Tooltip>
                                    ) : undefined} */}

                                    {users.status === 'ativo' ? (
                                      <Stack
                                        direction='row'
                                        alignItems='center'
                                        spacing={1}
                                      >
                                        <Typography>Não</Typography>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={
                                                updateInputValue(
                                                  users,
                                                  values,
                                                  'encerrar_voluntariado'
                                                ) == true
                                                  ? true
                                                  : false
                                              }
                                              onChange={handleChange}
                                              name='encerrar_voluntariado'
                                              disabled={isDisabled}
                                            />
                                          }
                                          labelPlacement='start'
                                        />
                                        <Typography>Sim</Typography>
                                      </Stack>
                                    ) : undefined}
                                  </Stack>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{ display: 'flex', gap: '1.5rem' }}
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    alignContent={'center'}
                                  >
                                    <Grid item xs={6}>
                                      <JumboTextField
                                        fullWidth
                                        name='data_fim'
                                        type='date'
                                        label='Data Final'
                                        value={updateInputValue(
                                          users,
                                          values,
                                          'data_fim'
                                        )}
                                        sx={{ background: '#ffffff' }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        disabled={isDisabled}
                                      />
                                    </Grid>

                                    <Grid item xs={6}>
                                      <JumboTextField
                                        fullWidth
                                        name='motivo_encerramento'
                                        label='Motivo'
                                        value={updateInputValue(
                                          users,
                                          values,
                                          'motivo_encerramento'
                                        )}
                                        disabled={isDisabled}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                              {values.encerrar_voluntariado == 'on' ? null : (
                                <Divider variant='middle' />
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Accordion
                                expanded={
                                  updateInputValue(
                                    users,
                                    values,
                                    'infos_usuario'
                                  ) == 'on'
                                    ? true
                                    : false
                                }
                                elevation={0}
                              >
                                <AccordionSummary>
                                  <Stack
                                    sx={{
                                      width: '100%',
                                      textAlign: { xs: 'center', lg: 'left' },
                                    }}
                                    direction={{ xs: 'column', lg: 'row' }}
                                    justifyContent='space-between'
                                    alignItems='center'
                                    spacing={2}
                                  >
                                    <Typography sx={{ fontSize: '1.05rem' }}>
                                      Informações sobre criação e atualização da
                                      atividade
                                    </Typography>

                                    <Stack
                                      direction='row'
                                      spacing={1}
                                      alignItems='center'
                                    >
                                      <Typography>Ocultar</Typography>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              updateInputValue(
                                                users,
                                                values,
                                                'infos_usuario'
                                              ) == 'on'
                                                ? true
                                                : false
                                            }
                                            onChange={handleChange}
                                            name='infos_usuario'
                                            disabled={isDisabled}
                                          />
                                        }
                                        labelPlacement='start'
                                      />
                                      <Typography>Visualizar</Typography>
                                    </Stack>
                                  </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid item xs={12}>
                                    <InfosAboutCreateAndUpdate
                                      data={users}
                                      quem_criou={
                                        users?.quem_aceitou_atividade_do_voluntario?.nome ??
                                        '-'
                                      }
                                      quem_atualizou={
                                        users?.quem_atualizou_atividade_do_voluntario?.nome ??
                                        '-'
                                      }
                                    />
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </>
                        ) : null}
                        {console.log(values)}
                        {values.encerrar_voluntariado == true ? ( // verifica se o switch é true
                          <Grid item xs={12} sx={{ textAlign: 'center', m: 3 }}>
                            <LoadingButton
                              color='error'
                              type='submit'
                              variant='contained'
                              size='small'
                              loading={isSubmitting}
                              disabled={
                                isDisabled ||
                                /* isInputFocused || */
                                /* status == false || */
                                values.data_fim == '' ||
                                values.data_fim == null ||
                                values.motivo_encerramento == null ||
                                values.motivo_encerramento == '' ||
                                isButtonDisabled
                              }
                            >
                              Encerrar atuação na atividade
                            </LoadingButton>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sx={{ textAlign: 'center', m: 3 }}>
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              gap={1}
                            >
                              <LoadingButton
                                color='success'
                                type='submit'
                                variant='contained'
                                size='small'
                                sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                                loading={isSubmitting}
                                disabled={
                                  isDisabled ||
                                  isInputFocused ||
                                  values.data_inicio == '' ||
                                  values.data_inicio == null
                                }
                              >
                                Atualizar
                              </LoadingButton>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </JumboContentLayout>
  );
};

export default VolunteerAlocationFormResumeEdit;
